<template>
  <div v-if="infos" class="user-info">
    <div class="top">
      <div class="btn" @click="goEdit">编辑资料</div>
    </div>
    <div class="header">
      <div class="avatar">
        <van-image
          round
          :src="avatar"
          class="img"
        />
      </div>
      <div class="phone">{{ phone }}</div>
    </div>
    <div class="content">
      <div class="list">
        <van-cell v-for="item in menu" :key="item.id" :title="item.title" is-link :value="item.value" :to="item.to">
          <div slot="icon" class="icon">
            <img :src="item.icon" alt="">
          </div>
          <div slot="default">
            <div v-if="item.status === 0"><van-icon name="warning" color="#e54a6f" /> {{ item.value }}</div>
          </div>
          <div slot="right-icon"><van-icon v-if="item.to" name="arrow" /></div>
        </van-cell>
      </div>
    </div>
  </div>
</template>
<script>
import { getOssUrl } from '@/utils/oss'
import { mapGetters } from 'vuex'
import icon1 from '@/assets/user/icon_1.png'
import icon2 from '@/assets/user/icon_2.png'
import icon3 from '@/assets/user/icon_3.png'
import { orderState } from '@/api/user'
import { getLocalUserInfo } from '@/utils/auth'
export default {
  data() {
    return {
      avatar: '',
      phone: '',
      menu: [
        { id: 1, title: '实名认证', value: '未实名', status: 0, to: 'userAuthentication', icon: icon1 },
        { id: 2, title: '贷款信息', value: '未填写', status: 0, icon: icon2 },
        { id: 3, title: '资质照片', value: '未上传', status: 0, icon: icon3 }
      ],
      infos: null
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  async mounted() {
    this.avatar = await getOssUrl(this.userInfos.headImgUrl)
    this.phone = this.userInfos.eaUserName
    const auditStatus = this.userInfos.auditStatus
    const baseInfoStatus = this.userInfos.baseInfoStatus
    await this.getOrderState()
    const hasMaterialImg = this.infos.hasMaterialImg
    if (auditStatus === 4) {
      this.menu[0].value = '已实名'
      this.menu[0].status = 1
      this.menu[0].to = 'userAuthenticationInfo'
    }
    if (baseInfoStatus === 1) {
      this.menu[1].value = ''
      this.menu[1].status = 1
      const orderId = this.infos.orderId || this.infos.lastOrderId
      this.menu[1].to = `loanEdit?orderId=${orderId}&readOnly=1`
    }
    if (hasMaterialImg === 1) {
      this.menu[2].value = ''
      this.menu[2].status = 1
      this.menu[2].to = 'userMaterial'
    }
  },
  methods: {
    goUrl(name) {
      this.$router.push({ name })
    },
    goEdit() {
      this.$router.push({ name: 'userInfoEdit' })
    },
    async getOrderState() {
      const { city } = getLocalUserInfo()
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await orderState(city)
      this.infos = res.datas
      loading.clear()
    }
  }
}
</script>
<style lang="scss" scoped>
  .user-info {
    .top {
      display: flex;
      justify-content: flex-end;
      padding: 16px;
      .btn {
        color: #666666;
      }
    }
    .header {
      padding: 48px 0;
      text-align: center;
      .avatar {
        .img {
          width: 74px;
          height: 74px;
        }
      }
      .phone {
        padding-top: 10px;
        font-size: 16px;
        color: #2E3135;
      }
    }
    .content {
      .list {
        background: #fff;
        .van-cell {
          align-items: center;
        }
        .icon {
          display: flex;
          align-items: center;
          padding-right: 10px;
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
</style>
